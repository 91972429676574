import http from './http-client';
import { CONSTANTS } from '../config/constants';
import { IBoard } from '../interfaces/board';

class BoardService {
  constructor() {}

  async getBoardData$(): Promise<IBoard> {
    try {
      const url = CONSTANTS.api.board.get;
      const response = await http(url);
      const body: IBoard = await response.data;
      return body;
    } catch (error: any) {
      console.error({ method: 'getBoardData', error });
      const message = error.message;
      throw new Error(message);
    }
  }
}

const boardService = new BoardService();

export default boardService;
