import './Header.scss';
import HeaderDropdown from './HeaderDropdown';

function Header() {
  return (
    <div id="header">
      <HeaderDropdown />
    </div>
  );
}

export default Header;
