import { TICKETS_GROUP_BY } from '../../../config/display';
import { ITicket, IUserMap } from '../../../interfaces/board';
import BoardCard from '../components/BoardCard';
import BoardColumnHeader from '../components/BoardColumnHeader';
import './BoardColumn.scss';

interface Props {
  tickets: ITicket[];
  userMap: IUserMap;
  heading: string;
  type: TICKETS_GROUP_BY;
}

function BoardColumn({ tickets, userMap, heading, type }: Props) {
  return (
    <div className="board-column">
      <BoardColumnHeader
        heading={heading}
        ticketsCount={tickets.length}
        userMap={userMap}
        type={type}
      />
      {tickets.map((ticket) => (
        <BoardCard
          ticket={ticket}
          user={userMap[ticket.userId]}
          key={ticket.id}
        />
      ))}
    </div>
  );
}

export default BoardColumn;
