import { AVATAR_COLOR } from '../../../config/avatar';
import { IUser } from '../../../interfaces/board';
import './BoardUserAvatar.scss';

interface Props {
  user: IUser;
}

function BoardUserAvatar({ user }: Props) {
  function getUserInitials(name: string) {
    const [firstName, lastName] = name.split(' ');
    if (!lastName) {
      return `${firstName[0]}`;
    }
    return `${firstName[0]}${lastName[0]}`;
  }

  function memoizedRandomColor(userId: string) {
    if (AVATAR_COLOR[userId]) {
      return AVATAR_COLOR[userId];
    }

    const hex = Math.floor(Math.random() * 0xffffff);
    const color = '#' + hex.toString(16);
    AVATAR_COLOR[userId] = color;
    return color;
  }

  return (
    <div
      className="board-user-avatar"
      title={`${user.name} (${user.available ? 'Available' : 'Not Available'})`}
    >
      <div
        className="avatar"
        style={{ backgroundColor: memoizedRandomColor(user.name) }}
      >
        {getUserInitials(user.name)}
        <div
          className="activity"
          style={{ backgroundColor: `${user.available ? '#00b91c' : '#ddd'}` }}
        ></div>
      </div>
    </div>
  );
}

export default BoardUserAvatar;
