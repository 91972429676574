import './BoardCardTag.scss';

function BoardCardTag({ tag }: { tag: string }) {
  return (
    <div className="board-card-tag">
      <i className="fa fa-circle text-muted"></i>
      <div>{tag}</div>
    </div>
  );
}

export default BoardCardTag;
