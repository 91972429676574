export const CONSTANTS = {
  api: {
    board: {
      get: 'internal/frontend-assignment',
    },
  },
  queryParamKeys: {
    groupBy: 'groupBy',
    sortBy: 'sortBy',
  },
};
