export enum TICKETS_GROUP_BY {
  STATUS = 'status',
  PRIORITY = 'priority',
  USER_ID = 'userId',
}

export enum TICKETS_SORT_BY {
  PRIORITY = 'priority',
  TITLE = 'title',
}

export const PRIORITY_MAP: { [key: string]: string } = {
  0: 'No priority',
  1: 'Low',
  2: 'Medium',
  3: 'High',
  4: 'Urgent',
};
