import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import boardService from '../../../services/daily-metrics';
import { ITicket, IUserMap } from '../../../interfaces/board';
import BoardColumn from '../containers/BoardColumn';
import { TICKETS_GROUP_BY, TICKETS_SORT_BY } from '../../../config/display';
import { CONSTANTS } from '../../../config/constants';
import './Board.scss';

function Board() {
  const [tickets, setTickets] = useState<ITicket[]>([]);
  const [userMap, setUserMap] = useState<IUserMap>({});
  const [groupedTickets, setGroupedTickets] = useState<{
    [key: string]: ITicket[];
  }>({});
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const groupBy = getGroupBy();
    const sortBy = getSortBy();
    rearrangeBoard(groupBy as TICKETS_GROUP_BY, sortBy as TICKETS_SORT_BY);
  }, [searchParams, tickets]);

  useEffect(() => {
    async function getData() {
      const data = await boardService.getBoardData$();
      setTickets(data.tickets);

      const groupedUserById: IUserMap = data.users.reduce((acc: any, user) => {
        acc[user.id] = user;
        return acc;
      }, {});
      setUserMap(groupedUserById);
    }
    getData();
  }, []);

  function rearrangeBoard(groupBy: TICKETS_GROUP_BY, sortBy: TICKETS_SORT_BY) {
    const rearrangedTickets = tickets.reduce((acc: any, ticket) => {
      if (!acc[ticket[groupBy]]) {
        acc[ticket[groupBy]] = [];
      }
      acc[ticket[groupBy]].push(ticket);
      acc[ticket[groupBy]].sort((a: any, b: any) => {
        if (sortBy === TICKETS_SORT_BY.TITLE) {
          return a.title.localeCompare(b.title);
        }
        if (sortBy === TICKETS_SORT_BY.PRIORITY) {
          return b.priority - a.priority;
        }
      });
      return acc;
    }, {});

    setGroupedTickets(rearrangedTickets);
  }

  function getGroupBy(): TICKETS_GROUP_BY {
    const groupBy = searchParams.get(CONSTANTS.queryParamKeys.groupBy);
    if (
      !groupBy ||
      !Object.values(TICKETS_GROUP_BY).includes(groupBy as TICKETS_GROUP_BY)
    ) {
      return TICKETS_GROUP_BY.STATUS;
    }
    return groupBy as TICKETS_GROUP_BY;
  }

  function getSortBy(): TICKETS_SORT_BY {
    const sortBy = searchParams.get(CONSTANTS.queryParamKeys.sortBy);
    if (
      !sortBy ||
      !Object.values(TICKETS_SORT_BY).includes(sortBy as TICKETS_SORT_BY)
    ) {
      return TICKETS_SORT_BY.PRIORITY;
    }
    return sortBy as TICKETS_SORT_BY;
  }

  return (
    <div id="board">
      {Object.keys(groupedTickets).map((key) => (
        <BoardColumn
          tickets={groupedTickets[key]}
          type={getGroupBy()}
          userMap={userMap}
          heading={key}
          key={key}
        />
      ))}
    </div>
  );
}

export default Board;
