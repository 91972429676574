import { TICKETS_PRIORITY } from '../../../interfaces/tickets';

function BoardColumnHeaderPriorityIcon({ heading }: { heading: number }) {
  return (
    <div className="text-muted">
      {TICKETS_PRIORITY.NO_PRIORITY == heading && (
        <i className="fa fa-ellipsis"></i>
      )}
      {TICKETS_PRIORITY.LOW == heading && <i className="fa fa-signal"></i>}
      {TICKETS_PRIORITY.MEDIUM == heading && <i className="fa fa-signal"></i>}
      {TICKETS_PRIORITY.HIGH == heading && <i className="fa fa-signal"></i>}
      {TICKETS_PRIORITY.URGENT == heading && (
        <i className="fa fa-triangle-exclamation text-warning"></i>
      )}
    </div>
  );
}

export default BoardColumnHeaderPriorityIcon;
