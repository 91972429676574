import { Navigate, createBrowserRouter } from 'react-router-dom';
import AppLayout from '../layouts/AppLayout';
import Board from '../pages/board/pages/Board';

const appRouter = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        path: 'board/:id',
        element: <Board />,
      },
      // TODO: temporary code to redirect to board/1
      {
        path: '/',
        element: <Navigate to="board/1" replace />,
      },
    ],
  },
]);

export default appRouter;
