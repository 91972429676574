import { TICKETS_STATUS } from '../../../interfaces/tickets';

function BoardColumnHeaderPriorityIcon({ heading }: { heading: string }) {
  return (
    <div className="text-muted">
      {TICKETS_STATUS.TODO == heading && (
        <i className="fa-regular fa-circle"></i>
      )}
      {TICKETS_STATUS.IN_PROGRESS == heading && (
        <i className="fa-solid fa-circle-half-stroke text-yellow"></i>
      )}
      {TICKETS_STATUS.BACKLOG == heading && (
        <i className="fa-solid fa-hourglass-start"></i>
      )}
      {TICKETS_STATUS.DONE == heading && (
        <i className="fa-solid fa-circle-check text-purple"></i>
      )}
      {TICKETS_STATUS.CANCELLED == heading && (
        <i className="fa fa-circle-times"></i>
      )}
    </div>
  );
}

export default BoardColumnHeaderPriorityIcon;
