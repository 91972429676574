import { useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useOnClickOutside } from 'usehooks-ts';
import { TICKETS_GROUP_BY, TICKETS_SORT_BY } from '../config/display';
import './HeaderDropdown.scss';
import { CONSTANTS } from '../config/constants';

function HeaderDropdown() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showOverlay, setShowOverlay] = useState(false);
  const overlayRef = useRef(null);

  useOnClickOutside(overlayRef, handleClickOutside);

  function handleClickOutside() {
    setShowOverlay(false);
  }

  function handleGroupByChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const sortBy =
      searchParams.get(CONSTANTS.queryParamKeys.sortBy) ||
      TICKETS_SORT_BY.PRIORITY;
    const groupBy = e.target.value;
    setSearchParams({ sortBy, groupBy });
  }

  function handleSortByChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const groupBy =
      searchParams.get(CONSTANTS.queryParamKeys.groupBy) ||
      TICKETS_GROUP_BY.PRIORITY;

    const sortBy = e.target.value;
    setSearchParams({ sortBy, groupBy });
  }

  function getGroupBy(): TICKETS_GROUP_BY {
    const groupBy = searchParams.get(CONSTANTS.queryParamKeys.groupBy);
    if (
      !groupBy ||
      !Object.values(TICKETS_GROUP_BY).includes(groupBy as TICKETS_GROUP_BY)
    ) {
      return TICKETS_GROUP_BY.STATUS;
    }
    return groupBy as TICKETS_GROUP_BY;
  }

  function getSortBy(): TICKETS_SORT_BY {
    const sortBy = searchParams.get(CONSTANTS.queryParamKeys.sortBy);
    if (
      !sortBy ||
      !Object.values(TICKETS_SORT_BY).includes(sortBy as TICKETS_SORT_BY)
    ) {
      return TICKETS_SORT_BY.PRIORITY;
    }
    return sortBy as TICKETS_SORT_BY;
  }

  return (
    <div className="header-dropdown" ref={overlayRef}>
      <div
        className="header-dropdown-button"
        onClick={() => setShowOverlay(!showOverlay)}
      >
        <i className="fa fa-sliders"></i>
        <strong className="ms-2">Display</strong>
        <i className="fa fa-angle-down ms-2"></i>
      </div>
      {showOverlay && (
        <div className="header-dropdown-overlay">
          <div>
            <span className="text-muted">Grouping</span>
            <select
              name="groupBy"
              id="groupBy"
              value={getGroupBy()}
              onChange={handleGroupByChange}
            >
              <option value={TICKETS_GROUP_BY.STATUS}>Status</option>
              <option value={TICKETS_GROUP_BY.PRIORITY}>Priority</option>
              <option value={TICKETS_GROUP_BY.USER_ID}>User</option>
            </select>
          </div>
          <div>
            <span className="text-muted">Ordering</span>
            <select
              name="sortBy"
              id="sortBy"
              value={getSortBy()}
              onChange={handleSortByChange}
            >
              <option value={TICKETS_SORT_BY.PRIORITY}>Priority</option>
              <option value={TICKETS_SORT_BY.TITLE}>Title</option>
            </select>
          </div>
        </div>
      )}
    </div>
  );
}

export default HeaderDropdown;
