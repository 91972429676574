import type { ITicket, IUser } from '../../../interfaces/board';
import BoardUserAvatar from './BoardUserAvatar';
import './BoardCard.scss';
import BoardCardTag from './BoardCardTag';
import { PRIORITY_MAP } from '../../../config/display';

interface Props {
  ticket: ITicket;
  user: IUser;
}

function BoardCard({ ticket, user }: Props) {
  return (
    <div className="board-card">
      <div>
        <div className="text-muted">{ticket.id}</div>
        <div className="board-card-heading" title={`Title: ${ticket.title}`}>
          {ticket.title}
        </div>
        <div className="d-flex">
          <div
            className="board-card-priority"
            title={`Priority: ${PRIORITY_MAP[ticket.priority]}`}
          >{`P${ticket.priority}`}</div>
          <div className="ms-2">
            {ticket.tag.map((tag) => (
              <BoardCardTag tag={tag} key={tag} />
            ))}
          </div>
        </div>
      </div>
      <div>
        <BoardUserAvatar user={user} />
      </div>
    </div>
  );
}

export default BoardCard;
