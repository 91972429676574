import { PRIORITY_MAP, TICKETS_GROUP_BY } from '../../../config/display';
import { IUserMap } from '../../../interfaces/board';
import './BoardColumnHeader.scss';
import BoardColumnHeaderPriorityIcon from './BoardColumnHeaderPriorityIcon';
import BoardColumnHeaderStatusIcon from './BoardColumnHeaderStatusIcon';
import BoardUserAvatar from './BoardUserAvatar';

interface Props {
  heading: string | number;
  ticketsCount: number;
  type: TICKETS_GROUP_BY;
  userMap: IUserMap;
}

function BoardColumnHeader({ heading, ticketsCount, type, userMap }: Props) {
  return (
    <div className="board-column-header">
      {type === 'status' && (
        <div className="d-flex">
          <BoardColumnHeaderStatusIcon heading={heading as string} />
          <div className="ms-3">
            {heading}
            <span className="text-muted ms-3">{ticketsCount}</span>
          </div>
        </div>
      )}

      {type === 'priority' && PRIORITY_MAP[heading] && (
        <div className="d-flex">
          <BoardColumnHeaderPriorityIcon heading={heading as number} />
          <div className="ms-3">
            {PRIORITY_MAP[heading]}
            <span className="text-muted ms-3">{ticketsCount}</span>
          </div>
        </div>
      )}

      {type === 'userId' && userMap[heading] && (
        <div className="d-flex">
          <BoardUserAvatar user={userMap[heading]} />
          <div className="ms-3">
            {userMap[heading]?.name}
            <span className="text-muted ms-3">{ticketsCount}</span>
          </div>
        </div>
      )}

      <div>
        <i className="fa fa-plus p-1 pointer" title="Add New Ticket"></i>
        <i className="fa fa-ellipsis p-1 pointer" title="Ticket Options"></i>
      </div>
    </div>
  );
}

export default BoardColumnHeader;
