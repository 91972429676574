export enum TICKETS_PRIORITY {
  NO_PRIORITY = 0,
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
  URGENT = 4,
}

export enum TICKETS_STATUS {
  TODO = 'Todo',
  IN_PROGRESS = 'In progress',
  DONE = 'Done',
  CANCELLED = 'Cancelled',
  BACKLOG = 'Backlog',
}
